import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ImageContainer = makeShortcode("ImageContainer");
const Caption = makeShortcode("Caption");
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Automatically pause the playing media when you get a call!`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://my.home-assistant.io/redirect/blueprint_import/?blueprint_url=https%3A%2F%2Fgist.github.com%2Fludeeus%2F58ab08b94424c12a8f911e932eb2f46a"
      }}><img parentName="a" {...{
          "src": "https://my.home-assistant.io/badges/blueprint_import.svg",
          "alt": "Open your Home Assistant instance and show the blueprint import dialog with a specific blueprint pre-filled."
        }}></img></a></p>
    <p>{`This Blueprint has some requirements before it's useful:`}</p>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Home Assistant Companion App for Android is set up (currently no iOS support)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Reporting of phone state is activated in the Android app`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`At least 1 media player entity`}</li>
    </ul>
    <ImageContainer mdxType="ImageContainer">
  <img src="/images/import_blueprint_spotify.png" />
    </ImageContainer>
    <Caption mdxType="Caption">
  <center>
    Example on how an automation can be made from the blueprint in the UI
  </center>
    </Caption>
    <Note mdxType="Note">
  Only entites can be used, if you select area or device you need to expand it
  untill you are left with only entites
    </Note>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# Example on how an automation can be made from the blueprint with YAML
automation pause_music_during_calls:
  alias: Pause Spotify when my phone rings
  use_blueprint:
    path: ludeeus/pause_media_during_calls.yaml
    input:
      phone_state_sensor: sensor.sm_g973f_phone_state
      media_player:
        entity_id: media_player.spotify_joakim_sorensen
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      